
import { defineComponent } from 'vue';
import { infos, media } from '../assets/info';
import Aos from 'aos';
import { useDark } from '@vueuse/core';

export default defineComponent({
  name: 'Info',
  data() {
    return {
      infos,
      hideLangUnrelated: false,
      isDark: useDark(),
    };
  },
  components: {},
  methods: {
    calcDelay(position: number) {
      let pos = position + 1;
      let mod = window.innerWidth < 480 ? 2 : window.innerWidth < 768 ? 3 : 4;
      return pos % mod == 1
        ? 0
        : pos % mod == 2
        ? 300
        : pos % mod == 3
        ? 600
        : (mod - 1) * 300;
    },
    getImgUrl(name: string) {
      var images = require.context('../assets/', false);
      return images('./' + name);
    },
    filterMedia(media: media[]): media[] {
      return media.filter(
        (m) =>
          !this.hideLangUnrelated ||
          m.lang.includes(this.$i18n.locale.substring(0, 2))
      );
    },
  },
  mounted() {
    document
      .querySelectorAll('img')
      .forEach((img) => img.addEventListener('load', () => Aos.refresh()));
    document
      .querySelectorAll('h1')
      .forEach((h1) => h1.addEventListener('load', () => Aos.refresh()));
  },
  computed: {
    getHeaderEffect() {
      return window.innerWidth > 480 ? 'fade-right' : '';
    },
  },
});
